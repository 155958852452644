<template>
  <div>
    <!-- Filters -->
    <organization-list-filters
      :country-filter.sync="countryFilter"
      :sector-filter.sync="sectorFilter"
      :advisor-filter.sync="advisorFilter"
      :country-options="countries"
      :sector-options="sectors"
      :advisor-options="advisors"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="table"
        class="position-relative"
        :items="currentPageItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(name)="data">
          <b-media>
            <template #aside>
              <b-avatar
                size="32"
                :src="logos[data.item['.key']]"
                :to="{ name: 'organizations-view', params: { id: data.item['.key'] } }"
              />
            </template>
            <b-link
              :to="{ name: 'organizations-view', params: { id: data.item['.key'] } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">Asesor: {{ data.item.advisor.name }}</small>
            &nbsp;&nbsp;
            <b-badge
              v-show="data.item.isInactive"
              variant="light-secondary"
            >
              Inactiva
            </b-badge>
          </b-media>

        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando del {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalfilteredItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BLink, BAvatar, BMedia,
  BPagination, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import OrganizationListFilters from './OrganizationListFilters.vue'
import organizations from '../../services/organizations'

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

export default {
  components: {
    OrganizationListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BAvatar,
    BMedia,
    BPagination,
    BBadge,
    vSelect,
  },
  data() {
    return {
      organizations: [],
      countryFilter: '',
      sectorFilter: '',
      advisorFilter: '',
      perPageOptions: [10, 25, 50, 100],
      perPage: 25,
      searchQuery: '',
      tableColumns: [
        { key: 'name', sortable: true, label: 'Nombre' },
        { key: 'city', sortable: true, label: 'Ciudad' },
        { key: 'country.name', sortable: true, label: 'País' },
        { key: 'sector', sortable: true, label: 'Sector' },
        { key: 'advisor.name', sortable: true, label: 'Asesor' },
      ],
      sortBy: '',
      isSortDirDesc: false,
      currentPage: 1,
      logos: [],
    }
  },
  computed: {
    countries() {
      return this.organizations.map(o => o.country.name).filter(onlyUnique).map(country => ({
        label: country,
        value: country,
      }))
    },
    sectors() {
      return this.organizations.map(o => o.sector).filter(onlyUnique).map(sector => ({
        label: sector,
        value: sector,
      }))
    },
    advisors() {
      return this.organizations.map(o => o.advisor.name).filter(onlyUnique).map(advisorName => ({
        label: advisorName,
        value: advisorName,
      }))
    },
    totalfilteredItems() {
      return this.filteredItems ? this.filteredItems.length : 0
    },
    dataMeta() {
      const localItemsCount = this.currentPageItems ? this.currentPageItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalfilteredItems,
      }
    },
    filteredItems() {
      let result = [...this.organizations]

      if (this.countryFilter) {
        result = result.filter(o => o.country.name === this.countryFilter)
      }

      if (this.sectorFilter) {
        result = result.filter(o => o.sector === this.sectorFilter)
      }

      if (this.advisorFilter) {
        result = result.filter(o => o.advisor.name === this.advisorFilter)
      }

      if (this.searchQuery) {
        const lowerCasedQuery = this.searchQuery.toLowerCase()
        result = result.filter(o => o.name.toLowerCase().indexOf(lowerCasedQuery) >= 0
            || o.city.toLowerCase().indexOf(lowerCasedQuery) >= 0)
      }

      return result
    },
    currentPageItems() {
      return this.filteredItems.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
    },
    userInfo() {
      return this.$store.state.services.userInfo
    },
  },
  watch: {
    async filteredItems(items) {
      const self = this
      items.forEach(async o => {
        if (self.logos[o['.key']] === undefined && o.logo) {
          const url = await organizations.getLogoURL(o['.key'], o.logo)
          self.logos[o['.key']] = url
          this.$refs.table.refresh()
        }
      })
    },
  },
  mounted() {
    switch (this.userInfo.type) {
      case 'Gerencia':
        this.$rtdbBind('organizations', organizations.bindAll())
        break
      case 'ClienteRed':
        this.$rtdbBind('organizations', organizations.bindAllByNetworkId(this.userInfo.network.id))
        break
      case 'Asesor':
        this.$rtdbBind('organizations', organizations.bindAllByAdvisorId(this.userInfo.id))
        break
      default:
        break
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
